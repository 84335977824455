var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c("div", { attrs: { id: "body-content-area" } }, [
          _vm.isLoading
            ? _c("div", [_c("Loading")], 1)
            : _c("div", { attrs: { id: "pagelayout" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmitLocal.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "columns", attrs: { id: "etp" } },
                      [
                        _c("div", { staticClass: "column" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "field is-grouped" }, [
                              _c("div", { staticClass: "field" }, [
                                _vm._m(1),
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.input.name,
                                        expression: "input.name",
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: { type: "text", name: "name" },
                                    domProps: { value: _vm.input.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.input,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "field" }, [
                                _vm._m(2),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.input
                                              .emailTemplateTypeCategoryId,
                                          expression:
                                            "input.emailTemplateTypeCategoryId",
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: { type: "text", name: "category" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.input,
                                              "emailTemplateTypeCategoryId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.getTokens(
                                              _vm.input.emailTemplateTypeId,
                                              _vm.input
                                                .emailTemplateTypeCategoryId
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      _vm.templateTypeCategories.filter(
                                        function (x) {
                                          return [
                                            "Reporting All",
                                            "Reporting Questionnaire",
                                          ].includes(x.description)
                                        }
                                      ),
                                      function (category) {
                                        return _c(
                                          "option",
                                          {
                                            key: category.emailTemplateTypeId,
                                            domProps: { value: category.id },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(category.description) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.maxHeight > 0
                      ? _c("div", { staticClass: "columns" }, [
                          _c("div", { staticClass: "column" }, [
                            _vm._m(3),
                            _c("div", { staticClass: "block" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "field is-grouped",
                                  attrs: { id: "esep" },
                                },
                                [
                                  _c("div", { staticClass: "field" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "label is-size-7",
                                        attrs: { id: "label" },
                                      },
                                      [_vm._v("Email Subject")]
                                    ),
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.input.subject,
                                            expression: "input.subject",
                                          },
                                        ],
                                        ref: "subject",
                                        staticClass: "input",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          size: "75",
                                        },
                                        domProps: { value: _vm.input.subject },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeFocus("subject")
                                          },
                                          onfocus: function ($event) {
                                            return _vm.changeFocus("subject")
                                          },
                                          onselect: function ($event) {
                                            return _vm.changeFocus("subject")
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.input,
                                              "subject",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "field" }, [
                                    _c(
                                      "label",
                                      { staticClass: "label is-size-7" },
                                      [_vm._v("Email Personalization")]
                                    ),
                                    _c("div", { staticClass: "control" }, [
                                      _c("div", { staticClass: "select" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.selectedToken,
                                                expression: "selectedToken",
                                              },
                                            ],
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.selectedToken = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                                _vm.personalizationChange,
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { selected: "" } },
                                              [
                                                _vm._v(
                                                  "Personalize the template"
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.tokens,
                                              function (token) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: token.value,
                                                    domProps: {
                                                      value: token.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(token.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "field" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label is-size-7",
                                    staticStyle: { top: "33px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFocus("body")
                                      },
                                      onfocus: function ($event) {
                                        return _vm.changeFocus("body")
                                      },
                                      onselect: function ($event) {
                                        return _vm.changeFocus("body")
                                      },
                                    },
                                  },
                                  [_vm._v("Email Body")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "control",
                                    style: {
                                      maxHeight: _vm.maxHeight + "px",
                                      height: _vm.maxheight + "px",
                                    },
                                  },
                                  [
                                    _c("tinymce", {
                                      ref: "editor",
                                      attrs: {
                                        id: "editor",
                                        init: {
                                          height: _vm.maxHeight,
                                        },
                                        plugins: "code",
                                        toolbar: "code",
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                        click: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                        onfocus: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                        onselect: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                        editorChange: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                        input: function ($event) {
                                          return _vm.changeFocus("body")
                                        },
                                      },
                                      model: {
                                        value: _vm.input.body,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.input, "body", $$v)
                                        },
                                        expression: "input.body",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { attrs: { id: "buttongroup" } }, [
                      _c("div", { staticClass: "field is-grouped" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-accent has-text-white",
                              attrs: {
                                disabled: !_vm.$hasPermissions(
                                  _vm.clientSession,
                                  ["EMAIL_TEMPLATES"],
                                  2
                                ),
                                type: "submit",
                              },
                            },
                            [_vm._v("Save")]
                          ),
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Email Template Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Template Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Template Type "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "etc" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Email Template Content"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }